.footer[data-v-5352546d] {
  position: relative;
  padding: var(--magic-space--medium, 24px);
  padding-bottom: calc(var(--magic-space--medium, 24px) + var(--magic-bottom-navigation-height, 64px));
  background-color: var(--magic-tertiary, #0b124f);
  box-shadow: inset 0 1px 0 0 var(--magic-footer-light-transparent, rgba(255, 255, 255, 0.1));
  text-align: center;
  color: var(--magic-tertiary-contrast, #fff);
}
@media (min-width: 768px) {
.footer[data-v-5352546d] {
    padding: var(--magic-space--large, 32px);
    padding-bottom: calc(var(--magic-space--medium, 24px) + var(--magic-bottom-navigation-height, 64px));
}
}
@media (min-width: 1024px) {
.footer[data-v-5352546d] {
    padding: var(--magic-space--x-medium, 48px);
}
}
.is-fullscreen .footer[data-v-5352546d], .footer.is-fullscreen[data-v-5352546d] {
  display: none;
}
.logo[data-v-5352546d] {
  background-image: url(../img/logo.6c00fe7d.svg);
  background-repeat: no-repeat;
  width: 133px;
  height: 92px;
  background-size: 100% 100%;
  position: relative;
  margin: 0 auto var(--magic-space--large, 32px);
}
.navigation[data-v-5352546d] {
  font-weight: var(--magic-font-weight-semibold, 600);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto calc(var(--magic-space--large, 32px) - var(--magic-space--small, 8px));
  width: 100%;
  max-width: var(--magic-footer-max-width, 600px);
}
@media (min-width: 1024px) {
.navigation[data-v-5352546d] {
    max-width: none;
}
}
.title[data-v-5352546d] {
  font-size: 1rem;
  margin: 0 0 var(--magic-space--small, 8px);
}
.text[data-v-5352546d] {
  font-size: 0.69rem;
  margin: 0 0 var(--magic-space--medium, 24px);
}
.link[data-v-5352546d] {
  margin: var(--magic-space--small, 8px) calc(var(--magic-space--small, 8px) + var(--magic-space--xx-small, 2px));
  text-decoration: none;
}
.link--logo[data-v-5352546d] {
  font-size: 0;
  display: block;
  flex: 1 0 100%;
  margin: var(--magic-space--small, 8px);
  width: 100%;
  height: 100%;
}
.icon[data-v-5352546d] {
  height: var(--magic-footer-logo-size, var(--magic-icon-size, 24px));
}
.social-media[data-v-5352546d] {
  margin: 0 auto var(--magic-space--medium, 24px);
}
.social-media-logos[data-v-5352546d] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 var(--magic-space, 16px);
  width: 100%;
}
@media (min-width: 1024px) {
.social-media-logos[data-v-5352546d] {
    padding: 0 var(--magic-space--large, 32px);
}
}
.social-media-logo[data-v-5352546d] {
  color: var(--magic-primary, #e9bb41);
  font-size: 0;
}