.app[data-v-4f30887e],
.content[data-v-4f30887e] {
  width: 100%;
  height: 100%;
}
.app[data-v-4f30887e] {
  --magic-offset-top: var(--magic-top-navigation-height, 56px);
  --magic-offset-bottom: var(--magic-bottom-navigation-height, 64px);
  --magic-top-nav-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-bottom-navigation-height, 64px));
  --magic-offset-left: 0px;
  --magic-offset-right: 0px;
  position: relative;
  display: flex;
  background-color: var(--magic-background, #fff);
  transition: background-color var(--magic-duration, 0.3s) ease;
  will-change: background-color;
}
.app.is-top-navigation-desktop[data-v-4f30887e] {
  --magic-top-navigation-height: 80px;
}
.app.is-sub-top-navigation-hidden[data-v-4f30887e] {
  --magic-top-navigation-height: 56px;
}
.app.is-sweepstakes[data-v-4f30887e] {
  --magic-game-navigation-landscape-width: 98px;
  --magic-offset-bottom: 0px;
}
.app.is-sweepstakes.is-game-play-mobile[data-v-4f30887e] {
  --magic-bottom-navigation-height: 0;
}
@media (orientation: landscape) {
.app.is-sweepstakes.is-game-play-mobile .top-navigation[data-v-4f30887e] {
    display: none;
}
}
.app.is-game-play-mobile[data-v-4f30887e] {
  --magic-offset-top: 0px;
}
.app.is-game-play-mobile-session[data-v-4f30887e] {
  --magic-offset-top: var(--magic-game-net-position-bar-height, 26px);
}
.app.is-sga[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-sga-bar-height--m, 50px));
}
.app.is-sga.is-game-play-mobile[data-v-4f30887e] {
  --magic-offset-top: var(--magic-sga-bar-height--m, 50px);
}
.app.is-sga.is-game-play-mobile-session[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-sga-bar-height--m, 50px) + var(--magic-game-net-position-bar-height, 26px));
  --magic-game-session-bar-top-offset: var(--magic-sga-bar-height--m, 50px);
}
.app.is-lotba[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-lotba-bar-height--m, 70px));
}
.app.is-lotba.is-game-play-mobile[data-v-4f30887e] {
  --magic-offset-top: var(--magic-lotba-bar-height--m, 70px);
}
.app.is-agco[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-agco-bar-height--m, 32px));
}
.app.is-agco.is-game-play-mobile[data-v-4f30887e] {
  --magic-offset-top: var(--magic-agco-bar-height--m, 32px);
}
.app.is-agco.is-game-play-mobile-session[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-agco-bar-height--m, 32px) + var(--magic-game-net-position-bar-height, 26px));
  --magic-game-session-bar-top-offset: var(--magic-agco-bar-height--m, 32px);
}
.app.is-emta[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-emta-bar-height--m, 32px));
}
.app.is-emta.is-game-play-mobile[data-v-4f30887e] {
  --magic-offset-top: var(--magic-emta-bar-height--m, 32px);
}
.app.is-emta.is-game-play-mobile-session[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-emta-bar-height--m, 32px) + var(--magic-game-net-position-bar-height, 26px));
  --magic-game-session-bar-top-offset: var(--magic-emta-bar-height--m, 32px);
}
.app.is-menu-hidden .main-navigation[data-v-4f30887e] {
  display: none;
}
.app.is-menu-hidden .content[data-v-4f30887e] {
  padding-left: 0;
}
.app.is-sportsbook-page-mobile[data-v-4f30887e] {
  --magic-offset-bottom: var(--magic-sportsbook-navigation-height, 50px);
}
.app.is-top-navigation-mobile[data-v-4f30887e] {
  --magic-offset-bottom: 0px;
  --magic-top-navigation-height: 104px;
}
.app.is-top-navigation-mobile.is-game-play-mobile[data-v-4f30887e] {
  --magic-top-navigation-height: unset;
}
.app.is-top-navigation-small[data-v-4f30887e] {
  --magic-top-navigation-height: unset;
}
.app.is-sga[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-sga-bar-height--m, 50px));
}
.app.is-emta[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-emta-bar-height--m, 32px));
}
@media (min-width: 1024px) {
.app[data-v-4f30887e] {
    --magic-offset-top: 0px;
    --magic-offset-bottom: 0px;
    --magic-offset-left: var(--magic-menu-width--d, 230px);
}
.app.is-sga[data-v-4f30887e] {
    --magic-offset-top: var(--magic-sga-bar-height--d, 44px);
}
.app.is-sga.is-fullscreen[data-v-4f30887e] {
    --magic-offset-top: var(--magic-sga-bar-height--d, 44px);
    --magic-offset-left: 0px;
}
.app.is-lotba[data-v-4f30887e] {
    --magic-offset-top: var(--magic-lotba-bar-height--d, 50px);
}
.app.is-lotba.is-fullscreen[data-v-4f30887e] {
    --magic-offset-top: var(--magic-lotba-bar-height--d, 50px);
    --magic-offset-left: 0px;
}
.app.is-agco[data-v-4f30887e] {
    --magic-offset-top: var(--magic-agco-bar-height--d, 32px);
}
.app.is-agco.is-fullscreen[data-v-4f30887e] {
    --magic-offset-top: var(--magic-agco-bar-height--d, 32px);
    --magic-offset-left: 0px;
}
.app.is-emta[data-v-4f30887e] {
    --magic-offset-top: var(--magic-emta-bar-height--d, 32px);
}
.app.is-emta.is-fullscreen[data-v-4f30887e] {
    --magic-offset-top: var(--magic-emta-bar-height--d, 32px);
    --magic-offset-left: 0px;
}
}
.is-fullscreen .app[data-v-4f30887e], .app.is-fullscreen[data-v-4f30887e] {
  --magic-offset-top: 0px;
  --magic-offset-left: 0px;
  --magic-top-navigation-height: 0px;
}
.content[data-v-4f30887e] {
  position: relative;
  padding: var(--magic-offset-top) var(--magic-offset-right) var(--magic-offset-bottom) var(--magic-offset-left);
  transition: padding-top var(--magic-duration, 0.3s) ease;
  will-change: padding-top;
}
.content--top-nav[data-v-4f30887e] {
  --magic-content-size: 1344px;
  --magic-offset-top: var(--magic-top-navigation-height, 56px);
  --magic-offset-left: 0;
  padding: var(--magic-top-navigation-height, 56px) 0 0;
}
.is-sga .content--top-nav[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-sga-bar-height--m, 50px));
}
.is-lotba .content--top-nav[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-lotba-bar-height--m, 70px));
}
.is-agco .content--top-nav[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-agco-bar-height--m, 32px));
}
.is-emta .content--top-nav[data-v-4f30887e] {
  --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-emta-bar-height--m, 32px));
}
@media (min-width: 1024px) {
.content--top-nav[data-v-4f30887e] {
    --magic-offset-top: var(--magic-top-navigation-height, 56px);
    padding: var(--magic-top-navigation-height, 56px) 0 0;
}
.is-sga .content--top-nav[data-v-4f30887e] {
    --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-sga-bar-height--d, 44px));
}
.is-lotba .content--top-nav[data-v-4f30887e] {
    --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-lotba-bar-height--d, 50px));
}
.is-agco .content--top-nav[data-v-4f30887e] {
    --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-agco-bar-height--d, 32px));
}
.is-emta .content--top-nav[data-v-4f30887e] {
    --magic-offset-top: calc(var(--magic-top-navigation-height, 56px) + var(--magic-emta-bar-height--d, 32px));
}
}
.content--top-nav-light[data-v-4f30887e] {
  padding: var(--magic-top-navigation-height, 56px) 0 0;
}
.is-sga .content--top-nav-light[data-v-4f30887e] {
  padding: calc(var(--magic-top-navigation-height, 56px) + var(--magic-sga-bar-height--m, 50px)) 0 0;
}
.is-lotba .content--top-nav-light[data-v-4f30887e] {
  padding: calc(var(--magic-top-navigation-height, 56px) + var(--magic-lotba-bar-height--m, 70px)) 0 0;
}
.is-agco .content--top-nav-light[data-v-4f30887e] {
  padding: calc(var(--magic-top-navigation-height, 56px) + var(--magic-agco-bar-height--m, 32px)) 0 0;
}
.is-emta .content--top-nav-light[data-v-4f30887e] {
  padding: calc(var(--magic-top-navigation-height, 56px) + var(--magic-emta-bar-height--m, 32px)) 0 0;
}
.page[data-v-4f30887e] {
  padding: var(--magic-space, 16px);
  min-height: calc(100vh - calc(var(--magic-offset-top) + var(--magic-offset-bottom)));
}
@media (min-width: 1024px) {
.page[data-v-4f30887e] {
    padding: var(--magic-space, 16px) calc(var(--magic-space--large, 32px) + var(--magic-space, 16px)) var(--magic-space, 16px);
}
}
.keycloak-content[data-v-4f30887e] {
  --magic-input-padding-vertical: calc(var(--magic-space--small, 8px) + var(--magic-space--xx-small, 2px));
  --magic-input-padding-icon: 50px;
  --magic-peek-password-toggle-right-space: var(--magic-space, 16px);
  --magic-form-field-icon-right-space: var(--magic-space, 16px);
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--magic-tertiary, #0b124f);
}
.keycloak-content .footer[data-v-4f30887e] {
  box-shadow: none;
  width: 100%;
}
@media (min-width: 1024px) {
.keycloak-content[data-v-4f30887e] {
    background-size: 100% 50%;
}
}
.logo[data-v-4f30887e] {
  background-image: url(../img/logo.6c00fe7d.svg);
  background-repeat: no-repeat;
  width: 133px;
  height: 92px;
  background-size: 100% 100%;
  margin-bottom: var(--magic-space--x-large, 64px);
  flex-shrink: 0;
}
@media (min-width: 768px) {
.logo[data-v-4f30887e] {
    margin-bottom: calc(var(--magic-space--x-large, 64px) + var(--magic-space, 16px) + var(--magic-space--small, 8px));
}
}
@media (min-width: 1024px) {
.logo[data-v-4f30887e] {
    margin-bottom: var(--magic-space--xx-large, 128px);
}
}
.keycloak-wrapper[data-v-4f30887e] {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--magic-space--large, 32px) 0 0;
  align-items: center;
  min-height: 100vh;
  background-image: var(--magic-keycloak-background-image, url("/img/keycloak-background.png"));
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}