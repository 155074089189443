.main-navigation[data-v-065c3942] {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 5;
}
.main-navigation--left[data-v-065c3942] {
  left: 0;
}
@media (min-width: 1024px) {
.main-navigation--left[data-v-065c3942] {
    width: var(--magic-menu-width--d, 230px);
    z-index: 2;
}
}
.navigation[data-v-065c3942] {
  overflow: auto;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  width: var(--magic-menu-width--m, 304px);
  height: 100%;
  background: var(--magic-tertiary, #0b124f);
  box-shadow: inset -1px 0 0 0 var(--magic-navigation-light-transparent, rgba(255, 255, 255, 0.1));
  color: var(--magic-tertiary-contrast, #fff);
  will-change: transform;
  pointer-events: all;
}
@media (pointer: coarse) {
.navigation[data-v-065c3942] {
    scroll-behavior: smooth;
}
}
.is-sga .navigation[data-v-065c3942] {
  top: var(--magic-sga-bar-height--m, 50px);
  height: calc(100% - var(--magic-sga-bar-height--m, 50px));
}
.is-lotba .navigation[data-v-065c3942] {
  top: var(--magic-lotba-bar-height--m, 70px);
  height: calc(100% - var(--magic-lotba-bar-height--m, 70px));
}
.is-agco .navigation[data-v-065c3942] {
  top: var(--magic-agco-bar-height--m, 32px);
  height: calc(100% - var(--magic-agco-bar-height--m, 32px));
}
.is-emta .navigation[data-v-065c3942] {
  top: var(--magic-emta-bar-height--m, 32px);
  height: calc(100% - var(--magic-emta-bar-height--m, 32px));
}
.navigation--left[data-v-065c3942] {
  left: 0;
  width: var(--magic-menu-width--m, 304px);
  transform: translate3d(-100%, 0, 0);
}
@media (min-width: 1024px) {
.navigation--left[data-v-065c3942] {
    width: var(--magic-menu-width--d, 230px);
    z-index: 0;
    transform: translate3d(0, 0, 0);
}
.is-sga .navigation--left[data-v-065c3942] {
    top: 0;
    padding-top: var(--magic-sga-bar-height--d, 44px);
    height: 100%;
    transform: none;
}
.is-lotba .navigation--left[data-v-065c3942] {
    top: 0;
    padding-top: var(--magic-lotba-bar-height--d, 50px);
    height: 100%;
    transform: none;
}
.is-agco .navigation--left[data-v-065c3942] {
    top: 0;
    padding-top: var(--magic-agco-bar-height--d, 32px);
    height: 100%;
    transform: none;
}
.is-emta .navigation--left[data-v-065c3942] {
    height: 100%;
    transform: none;
}
}
.is-fullscreen .navigation--left[data-v-065c3942], .navigation--left.is-fullscreen[data-v-065c3942] {
  display: none;
}
.navigation--right[data-v-065c3942] {
  right: 0;
  width: calc(var(--magic-menu-width--m, 304px) - var(--magic-space--large, 32px));
  transform: translate3d(100%, 0, 0);
}
@media (min-width: 1024px) {
.navigation--right[data-v-065c3942] {
    width: var(--magic-main-navigation-top-enabled-width, 280px);
    z-index: 0;
}
.is-lotba .navigation--right[data-v-065c3942] {
    top: var(--magic-lotba-bar-height--d, 50px);
    height: calc(100% - var(--magic-lotba-bar-height--d, 50px));
}
.is-sga .navigation--right[data-v-065c3942] {
    top: var(--magic-sga-bar-height--d, 44px);
    height: calc(100% - var(--magic-sga-bar-height--d, 44px));
}
}
.navigation--open[data-v-065c3942] {
  z-index: 1;
  animation: slideIn-data-v-065c3942 var(--magic-duration, 0.3s) ease forwards;
}
@keyframes slideIn-data-v-065c3942 {
from {
    transform: translate3d(-100%, 0, 0);
}
to {
    transform: translate3d(0, 0, 0);
}
}
.navigation--open-right[data-v-065c3942] {
  z-index: 1;
  animation: slideInFromRight-data-v-065c3942 var(--magic-duration, 0.3s) ease forwards;
}
@keyframes slideInFromRight-data-v-065c3942 {
from {
    transform: translate3d(100%, 0, 0);
}
to {
    transform: translate3d(0, 0, 0);
}
}
.navigation--open ~ .backdrop[data-v-065c3942],
.navigation--open-right ~ .backdrop[data-v-065c3942] {
  opacity: 1;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--magic-dark, #000);
  opacity: 0.5;
  will-change: opacity;
  pointer-events: all;
}
.navigation--open ~ .backdrop.fade-in-enter-active[data-v-065c3942], .navigation--open ~ .backdrop.fade-in-leave-active[data-v-065c3942],
.navigation--open-right ~ .backdrop.fade-in-enter-active[data-v-065c3942],
.navigation--open-right ~ .backdrop.fade-in-leave-active[data-v-065c3942] {
  transition: opacity var(--magic-duration, 0.3s) ease;
}
.navigation--open ~ .backdrop.fade-in-enter[data-v-065c3942], .navigation--open ~ .backdrop.fade-in-leave-to[data-v-065c3942],
.navigation--open-right ~ .backdrop.fade-in-enter[data-v-065c3942],
.navigation--open-right ~ .backdrop.fade-in-leave-to[data-v-065c3942] {
  opacity: 0;
}
@media (min-width: 1024px) {
.backdrop[data-v-065c3942] {
    display: none;
}
}
.is-fullscreen .backdrop[data-v-065c3942], .backdrop.is-fullscreen[data-v-065c3942] {
  display: none;
}
@media (min-width: 1024px) {
.backdrop--right[data-v-065c3942] {
    display: block;
}
}
.logo[data-v-065c3942] {
  background-image: url(../img/logo.6c00fe7d.svg);
  background-repeat: no-repeat;
  width: 80.9565217391px;
  height: 56px;
  background-size: 100% 100%;
  margin: calc(var(--magic-space, 16px) + var(--magic-space--xx-small, 2px) + var(--magic-space--xxx-small, 1px)) var(--magic-space, 16px) var(--magic-space, 16px);
  flex: 0 0 auto;
  cursor: pointer;
}
@media (min-width: 1024px) {
.logo[data-v-065c3942] {
    background-image: url(../img/logo.6c00fe7d.svg);
    background-repeat: no-repeat;
    width: 133px;
    height: 92px;
    background-size: 100% 100%;
    margin: var(--magic-space--large, 32px) auto calc(var(--magic-space, 16px) + var(--magic-space--small, 8px));
}
}
.items[data-v-065c3942] {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.items--logged-in[data-v-065c3942] {
  padding-top: var(--magic-space--small, 8px);
}
.items--logged-out[data-v-065c3942] {
  padding-top: var(--magic-space, 16px);
}
.item[data-v-065c3942] {
  display: block;
}
.item--live-chat[data-v-065c3942] {
  display: none;
  margin-top: auto;
  height: 40px;
}
.item--live-chat .icon[data-v-065c3942] {
  margin: var(--magic-space--xx-small, 2px) 0 0;
  width: var(--magic-space, 16px);
  height: var(--magic-space, 16px);
}
.item--live-chat .text[data-v-065c3942] {
  font-size: 0.81rem;
  margin: 0 0 0 var(--magic-space--small, 8px);
}
.item--top-nav[data-v-065c3942] {
  margin-top: var(--magic-space--x-small, 4px);
}
.item--divider-up[data-v-065c3942] {
  box-shadow: 0 calc(-1 * (1px + var(--magic-space, 16px))) 0 calc(-1 * var(--magic-space, 16px)) var(--magic-item-light-transparent, rgba(255, 255, 255, 0.25));
}
.item--divider-down[data-v-065c3942] {
  box-shadow: 0 calc(1px + var(--magic-space, 16px)) 0 calc(-1 * var(--magic-space, 16px)) var(--magic-item-light-transparent, rgba(255, 255, 255, 0.25));
}
.link[data-v-065c3942] {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: var(--magic-space--small, 8px) var(--magic-space, 16px);
  height: 100%;
  text-decoration: none;
  transition: background-color var(--magic-duration, 0.3s) ease;
  will-change: background-color;
}
.link--left[data-v-065c3942] {
  width: 100%;
}
.link--right[data-v-065c3942] {
  margin: auto var(--magic-space, 16px);
  border-radius: var(--magic-border-radius, 4px);
}
.link[data-v-065c3942]:hover, .link[data-v-065c3942]:focus {
  background-color: rgba(255, 255, 255, 0.075);
}
.link.is-active[data-v-065c3942] {
  background-color: rgba(255, 255, 255, 0.075);
  color: var(--magic-primary, #e9bb41);
}
.icon[data-v-065c3942] {
  width: var(--magic-icon-size, 24px);
  height: var(--magic-icon-size, 24px);
}
.icon--close[data-v-065c3942] {
  position: absolute;
  top: var(--magic-space, 16px);
  right: var(--magic-space, 16px);
  margin: 0;
  cursor: pointer;
}
.icon--right[data-v-065c3942] {
  right: var(--magic-space, 16px);
}
.text[data-v-065c3942] {
  font-size: 1rem;
  margin: 0 0 0 calc(var(--magic-space--small, 8px) + var(--magic-space--x-small, 4px));
}
.clock[data-v-065c3942] {
  font-size: 0.81rem;
  position: absolute;
  bottom: calc(var(--magic-space, 16px) - var(--magic-space--x-small, 4px));
  right: var(--magic-space, 16px);
}
.buttons[data-v-065c3942] {
  padding: 0 var(--magic-space, 16px);
  width: 100%;
}
.balance[data-v-065c3942] {
  width: 100%;
  padding: var(--magic-space, 16px);
  background-color: var(--magic-balance-light-transparent, rgba(255, 255, 255, 0.1));
}
.accordion[data-v-065c3942] {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: var(--magic-space, 16px) 0;
  padding: var(--magic-space, 16px);
  width: 100%;
  box-shadow: 0 calc((var(--magic-space, 16px) * -1) - 2px) 0 calc(var(--magic-space, 16px) * -1) var(--magic-accordion-light-transparent, rgba(255, 255, 255, 0.25)), 0 calc(var(--magic-space, 16px) + 2px) 0 calc(var(--magic-space, 16px) * -1) var(--magic-accordion-light-transparent, rgba(255, 255, 255, 0.25));
  text-decoration: none;
}
.accordion .items[data-v-065c3942] {
  margin: var(--magic-space--small, 8px) 0 0;
}
.accordion .item[data-v-065c3942] {
  margin: 0 0 var(--magic-space--x-small, 4px);
  text-align: center;
}
.accordion .item[data-v-065c3942]:last-child {
  margin: 0;
}
.accordion .text[data-v-065c3942] {
  font-size: 1rem;
  font-weight: var(--magic-font-weight-semibold, 600);
  margin: 0;
}
.accordion .text--item[data-v-065c3942] {
  font-weight: var(--magic-font-weight, 400);
  margin: 0;
}
.accordion .text--top-nav[data-v-065c3942] {
  font-size: 1rem;
  font-weight: var(--magic-font-weight-semibold, 600);
}
.accordion .link[data-v-065c3942] {
  display: inline-block;
  padding: var(--magic-space--x-small, 4px);
  width: auto;
}
.accordion .link[data-v-065c3942]:hover, .accordion .link[data-v-065c3942]:focus {
  background-color: transparent;
  text-decoration: underline;
  color: var(--magic-primary, #e9bb41);
}
.accordion .link.is-active[data-v-065c3942] {
  background-color: transparent;
  text-decoration: underline;
  color: var(--magic-primary, #e9bb41);
}
.accordion .link-top-nav[data-v-065c3942] {
  display: inline-block;
  padding: 0 var(--magic-space, 16px);
  align-content: center;
  width: 100%;
  border-radius: var(--magic-border-radius, 4px);
}
.accordion .link-top-nav[data-v-065c3942]:hover, .accordion .link-top-nav[data-v-065c3942]:focus {
  background-color: rgba(255, 255, 255, 0.075);
  text-decoration: none;
}
.accordion .link-top-nav.is-active[data-v-065c3942] {
  background-color: rgba(255, 255, 255, 0.075);
  text-decoration: none;
}
.accordion--top-nav[data-v-065c3942] {
  align-items: flex-start;
  margin: var(--magic-space, 16px) 0 var(--magic-space--x-small, 4px);
  padding: 0 var(--magic-space, 16px) var(--magic-space--small, 8px);
  width: 100%;
  box-shadow: 0 calc(1px + var(--magic-space, 16px)) 0 calc(-1 * var(--magic-space, 16px)) var(--magic-item-light-transparent, rgba(255, 255, 255, 0.25));
  text-decoration: none;
}
.accordion--top-nav .items[data-v-065c3942] {
  padding-left: calc(var(--magic-space--large, 32px) + var(--magic-space--x-small, 4px));
  margin: 0;
}
.accordion--top-nav .item[data-v-065c3942] {
  text-align: left;
  align-items: center;
  height: calc(var(--magic-space--large, 32px) + var(--magic-space--small, 8px));
  margin: var(--magic-space--x-small, 4px) 0 0;
}
.accordion--top-nav .item[data-v-065c3942]:last-child {
  margin-top: var(--magic-space--x-small, 4px);
}
.toggle-top-nav[data-v-065c3942] {
  display: inline-flex;
  margin-left: var(--magic-space--small, 8px);
}
.accordion-title[data-v-065c3942] {
  padding-left: calc(var(--magic-space--small, 8px) + var(--magic-space--x-small, 4px));
  font-weight: var(--magic-font-weight-semibold, 600);
}
.badge[data-v-065c3942] {
  position: absolute;
  top: calc(var(--magic-space--small, 8px) + var(--magic-space--xx-small, 2px));
  right: calc(var(--magic-space, 16px) + var(--magic-space--small, 8px));
}
.badge--top-nav[data-v-065c3942] {
  --magic-badge-background: var(--magic-secondary, #ce32af);
  right: 0;
}
.progress-bar[data-v-065c3942] {
  display: block;
  padding: 0 var(--magic-space, 16px);
  width: 100%;
}
.progress-bar--top-nav[data-v-065c3942] {
  --magic-progress-bar-height: var(--magic-progress-bar-height, 12px);
  padding: 0 var(--magic-space, 16px);
  margin: 0;
  margin-top: var(--magic-space, 16px);
}
.navigation--right-clock[data-v-065c3942] {
  margin: calc(var(--magic-space, 16px) + var(--magic-space--small, 8px)) var(--magic-space, 16px) var(--magic-space, 16px);
}
.promotion[data-v-065c3942] {
  display: flex;
  flex-direction: column;
  margin: 0 var(--magic-space, 16px);
  position: relative;
  padding: var(--magic-space--small, 8px);
  width: calc(100% - var(--magic-space--large, 32px));
}
.promotion-gradiant[data-v-065c3942] {
  background: linear-gradient(90deg, var(--magic-dark, #000), transparent 50%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.promotion-background-image[data-v-065c3942] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: -1;
  border-radius: var(--magic-border-radius, 4px);
}
.promotion-title[data-v-065c3942] {
  font-size: 1.75rem;
  font-weight: var(--magic-font-weight-bold, 700);
  line-height: 1.4em;
  max-width: 60%;
  margin: var(--magic-space--small, 8px) 0 0 0;
  position: relative;
}
.promotion-subtitle[data-v-065c3942] {
  font-size: 0.75rem;
  font-weight: var(--magic-font-weight-semibold, 600);
  line-height: 1.4em;
  position: relative;
}
.promotion-subtitle-tag[data-v-065c3942] {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60%;
  background: var(--magic-secondary, #ce32af);
  border-radius: 100px;
  padding: calc(var(--magic-space--x-small, 4px) - var(--magic-space--xxx-small, 1px)) calc(var(--magic-space, 16px) - var(--magic-space--x-small, 4px));
  position: relative;
}
.promotion-button[data-v-065c3942] {
  margin: var(--magic-space--small, 8px) auto 0 0;
}
.promotion-eye-button[data-v-065c3942] {
  background: var(--magic-light, #fff);
  border-radius: 100%;
  padding: var(--magic-space--xx-small, 2px);
  position: relative;
  width: var(--magic-main-navigation-promotion-eye-width, 24px);
  height: var(--magic-main-navigation-promotion-eye-height, 24px);
}
.promotion-top[data-v-065c3942] {
  display: flex;
  justify-content: space-between;
  margin-top: var(--magic-space--small, 8px);
}
.item--top-nav.item--divider-up[data-v-065c3942] {
  padding-top: var(--magic-space--small, 8px);
  margin-top: var(--magic-space--small, 8px);
}
.item--top-nav.item--divider-down[data-v-065c3942] {
  padding-bottom: var(--magic-space--small, 8px);
  margin-bottom: var(--magic-space--small, 8px);
}