@font-face {
  font-family: "Montserrat";
  src: url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/NewakeRegular.woff2") format("woff2"), url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/NewakeRegular.woff") format("woff"), url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/NewakeRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Montserrat";
  src: url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/Montserrat-Regular.woff2") format("woff2"), url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/Montserrat-Regular.woff") format("woff"), url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Montserrat";
  src: url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/Montserrat-SemiBold.woff2") format("woff2"), url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/Montserrat-SemiBold.woff") format("woff"), url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Montserrat";
  src: url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/Montserrat-Bold.woff2") format("woff2"), url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/Montserrat-Bold.woff") format("woff"), url("https://magic-static-goldrushcity.s3.eu-central-1.amazonaws.com/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
html {
  --magic-primary: #e9bb41;
  --magic-secondary: #ce32af;
  --magic-tertiary: #0b124f;
  --magic-background: #fff;
  --magic-text: #747474;
  --magic-text-primary: #1726a5;
  --magic-caption: #161616;
  --magic-header: #161616;
  --magic-primary-contrast: #070a2e;
  --magic-success: #45bf55;
  --magic-alert: #fa5a0f;
  --magic-error: #d60000;
  --magic-dark: #000;
  --magic-medium: #747474;
  --magic-light: #fff;
  --magic-disabled: #e6e7f0;
  --magic-font-family: Montserrat, Helvetica, Arial, sans-serif;
  --magic-header-font-family: Montserrat, Helvetica, Arial, sans-serif;
  --magic-font-size: 14px;
  --magic-font-weight: 400;
  --magic-line-height: 1.5;
  --magic-text-info: #0c66ff;
  --magic-text-success: #45bf55;
  --magic-text-alert: #fa5a0f;
  --magic-text-error: #d60000;
  --magic-text-dark: #070a2e;
  --magic-text-medium: #50535f;
  --magic-text-light: #fff;
  --magic-text-lighter: #868bb5;
  --magic-gradient-1: #e9bb41;
  --magic-duration: 0.3s;
  --magic-delay: 0.3s;
  --magic-step-progress-bar-color: rgba(233, 187, 65, 0.5);
  --magic-progress-bar-filler-from: #ff3ed9;
  --magic-progress-bar-filler-to: #691959;
  --magic-radio-border: #50535f;
  --magic-radio-hover-border: #e9bb41;
  --magic-radio-checked-border: #e9bb41;
  --magic-radio-background: #e9bb41;
  --magic-radio-focus-border: #e9bb41;
  --magic-badge-background: #ce32af;
  --magic-switch-checked-background: #e9bb41;
  --magic-switch-hover-border: #e9bb41;
  --magic-switch-focus-border: #e9bb41;
  --magic-account-menu-background: #e9bb41;
  --magic-button-disabled-darker: #aaaecc;
  --magic-button-primary-darker: rgba(233, 187, 65, 0.8);
  --magic-button-primary-transparent: rgba(233, 187, 65, 0.125);
  --magic-checkbox-border: #50535f;
  --magic-checkbox-hover-border: #e9bb41;
  --magic-checkbox-checked-border: #e9bb41;
  --magic-checkbox-background: #e9bb41;
  --magic-checkbox-focus-border: #e9bb41;
  --magic-icon-badge-background: #ce32af;
  --magic-icon-badge-color: #fff;
  --magic-file-upload-header-dark-transparent: #f6f7ff;
  --magic-tag-bonus-background: #45bf55;
  --magic-tag-text-color: #fff;
  --magic-keycloak-theme-content-size-m: 343px;
}

html.color-theme-in-transition,
html.color-theme-in-transition *,
html.color-theme-in-transition *::before,
html.color-theme-in-transition *::after {
  transition: all 0.75s;
  transition-delay: 0;
}

html[data-theme=dark] {
  --magic-tertiary: #02030c;
  --magic-tertiary-contrast: #fff;
  --magic-disabled: #767cad;
  --magic-background: #1a1a1a;
  --magic-background-light: #343434;
  --magic-background-dark: #1a1a1a;
  --magic-text: #fff;
  --magic-caption: #868bb5;
  --magic-header: #868bb5;
  --magic-shadow: rgba(0, 0, 0, 0.75);
  --magic-shadow-light: rgba(0, 0, 0, 0.45);
  --magic-shadow-lighter: rgba(0, 0, 0, 0.25);
  --magic-input-border: #4e4e4e;
  --magic-dropdown-border: #4e4e4e;
  --magic-selects-border: #4e4e4e;
  --magic-form-field-message-background: #212337;
  --magic-password-indicator-background: #212337;
}

@media (prefers-color-scheme: dark) {
  html:not([data-theme=light]) {
    --magic-tertiary: #02030c;
    --magic-tertiary-contrast: #fff;
    --magic-disabled: #767cad;
    --magic-background: #1a1a1a;
    --magic-background-light: #343434;
    --magic-background-dark: #1a1a1a;
    --magic-text: #fff;
    --magic-caption: #868bb5;
    --magic-header: #868bb5;
    --magic-shadow: rgba(0, 0, 0, 0.75);
    --magic-shadow-light: rgba(0, 0, 0, 0.45);
    --magic-shadow-lighter: rgba(0, 0, 0, 0.25);
    --magic-input-border: #4e4e4e;
    --magic-dropdown-border: #4e4e4e;
    --magic-selects-border: #4e4e4e;
    --magic-form-field-message-background: #212337;
    --magic-password-indicator-background: #212337;
  }
}