.top-navigation[data-v-86b512cc] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--magic-top-navigation-height, 56px);
  background-color: var(--magic-tertiary, #0b124f);
  box-shadow: inset 0 -1px 0 0 var(--magic-top-navigation-light-transparent, rgba(255, 255, 255, 0.1));
  color: var(--magic-tertiary-contrast, #fff);
  z-index: 2;
  transition: transform calc(var(--magic-duration, 0.3s) / 2) ease;
  will-change: transform;
}
.top-navigation--light[data-v-86b512cc] {
  background-color: var(--magic-tertiary, #0b124f);
  box-shadow: 0 1px 2px 0 var(--magic-top-navigation-medium-transparent, rgba(116, 116, 116, 0.24));
  color: var(--magic-text-light, #fff);
}
.top-navigation--light .balance[data-v-86b512cc] {
  margin-left: auto;
  color: var(--magic-caption, #161616);
}
html[data-theme=dark] .top-navigation--light[data-v-86b512cc] {
  background-color: var(--magic-tertiary, #0b124f);
}
@media (prefers-color-scheme: dark) {
html:not([data-theme=light]) .top-navigation--light[data-v-86b512cc] {
    background-color: var(--magic-tertiary, #0b124f);
}
}
.top-navigation--top-nav--light[data-v-86b512cc] {
  box-shadow: 0 1px 2px 0 var(--magic-top-navigation-medium-transparent, rgba(116, 116, 116, 0.24));
}
.top-navigation.is-hidden[data-v-86b512cc] {
  transform: translate3d(0, -100%, 0);
}
.is-agco.is-game-play-mobile-session .top-navigation[data-v-86b512cc] {
  transform: translate3d(0, calc(var(--magic-agco-bar-height--m, 32px) + var(--magic-game-net-position-bar-height, 26px)), 0);
}
@media (min-width: 1024px) {
.top-navigation[data-v-86b512cc] {
    display: none;
}
}
.is-fullscreen .top-navigation[data-v-86b512cc], .top-navigation.is-fullscreen[data-v-86b512cc] {
  display: none;
}
@media (min-width: 1024px) {
.top-navigation.is-top-nav-enabled[data-v-86b512cc] {
    display: block;
}
}
@media (min-width: 1024px) {
.top-navigation.is-game-play-mobile[data-v-86b512cc] {
    display: block;
}
}
.top-navigation.is-game-play-mobile .top-nav--nav-container[data-v-86b512cc] {
  display: none;
}
.wrapper[data-v-86b512cc] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--magic-space, 16px);
  width: 100%;
  height: 100%;
}
.logo[data-v-86b512cc] {
  background-image: url(../img/logo.6c00fe7d.svg);
  background-repeat: no-repeat;
  width: 57.8260869565px;
  height: 40px;
  background-size: 100% 100%;
}
.action[data-v-86b512cc] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  padding: 0 calc(var(--magic-space--small, 8px) + var(--magic-space--x-small, 4px));
  width: 100%;
  height: var(--magic-top-navigation-height, 56px);
}
.buttons[data-v-86b512cc] {
  display: flex;
  gap: var(--magic-space--x-small, 4px);
  margin: 0 calc((var(--magic-space--x-small, 4px) / 2) * -1) 0 0;
}
.button--left[data-v-86b512cc] {
  margin-right: auto;
}
.button--right[data-v-86b512cc] {
  margin-left: auto;
}
.title[data-v-86b512cc] {
  font-size: 1rem;
  margin: 0;
  padding: 0 var(--magic-icon-size, 24px);
  width: 100%;
  text-align: center;
}
.icon[data-v-86b512cc] {
  width: var(--magic-icon-size, 24px);
  height: var(--magic-icon-size, 24px);
  color: var(--magic-background, #fff);
  cursor: pointer;
}
.icon--top-nav[data-v-86b512cc] {
  color: var(--magic-tertiary-contrast, #fff);
}
.icon--help[data-v-86b512cc] {
  color: var(--magic-primary, #e9bb41);
}
.top-nav--wrapper[data-v-86b512cc] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: var(--magic-top-navigation-height, 56px);
  transition: height 0.25s ease-in-out;
}
@media (min-width: 1024px) {
.top-nav--wrapper[data-v-86b512cc] {
    flex-wrap: nowrap;
    justify-content: flex-start;
}
}
.top-nav--nav-container[data-v-86b512cc] {
  border-top: 1px solid var(--magic-top-navigation-border-opacity, rgba(255, 255, 255, 0.2));
  display: flex;
  justify-content: space-between;
  order: 3;
  width: 100%;
  overflow: auto;
  align-items: center;
  position: relative;
  pointer-events: auto;
  opacity: 1;
  transition: transform 0.3s ease-in-out, opacity 0.15s ease-in-out, pointer-events 0s;
}
.top-nav--nav-container[data-v-86b512cc]::-webkit-scrollbar, .top-nav--nav-container[data-v-86b512cc]::-webkit-scrollbar-track, .top-nav--nav-container[data-v-86b512cc]::-webkit-scrollbar-thumb, .top-nav--nav-container[data-v-86b512cc]::-webkit-scrollbar-track-piece:start, .top-nav--nav-container[data-v-86b512cc]::-webkit-scrollbar-track-piece:end {
  display: none;
  width: 0;
  height: 0;
  background: transparent none 0 0;
}
.top-nav--nav-container.hidden[data-v-86b512cc] {
  opacity: 0;
  pointer-events: none;
}
@media (min-width: 1024px) {
.top-nav--nav-container[data-v-86b512cc] {
    border-top: none;
    margin-right: auto;
    order: 2;
    width: auto;
    overflow: visible;
    position: static;
    top: auto;
    left: auto;
    right: auto;
    transition: none;
}
}
.top-nav--logo[data-v-86b512cc] {
  margin: var(--magic-space, 16px);
}
@media (min-width: 1024px) {
.top-nav--logo[data-v-86b512cc] {
    margin: auto calc(var(--magic-space--large, 32px) + var(--magic-space, 16px));
}
}
.top-nav--buttons[data-v-86b512cc] {
  margin: calc(var(--magic-space, 16px) - var(--magic-space--x-small, 4px));
  margin-right: var(--magic-space, 16px);
  align-items: center;
  display: flex;
  height: var(--magic-space--large, 32px);
  order: 2;
}
@media (min-width: 1024px) {
.top-nav--buttons[data-v-86b512cc] {
    margin-right: calc(var(--magic-space--large, 32px) + var(--magic-space, 16px));
}
}
.top-nav--button[data-v-86b512cc] {
  margin-right: var(--magic-space--small, 8px);
}
.navigation-links[data-v-86b512cc] {
  font-size: 1rem;
  align-items: center;
  display: flex;
  flex: 1;
  height: var(--magic-top-navigation-tab-height, 48px);
  justify-content: center;
  position: relative;
  white-space: nowrap;
  min-width: 30%;
}
@media (min-width: 1024px) {
.navigation-links[data-v-86b512cc] {
    font-size: 0.88rem;
    border-right-color: transparent;
    margin: var(--magic-space--x-small, 4px) var(--magic-space--small, 8px);
    min-width: auto;
}
}
@media (min-width: 1024px) {
.navigation-links--logged-in[data-v-86b512cc] {
    margin: var(--magic-space--x-small, 4px) var(--magic-space--small, 8px);
}
}
@media (min-width: 1024px) {
.navigation-links--logged-out[data-v-86b512cc] {
    margin: var(--magic-space--x-small, 4px) var(--magic-space, 16px);
}
}
.navigation-link[data-v-86b512cc] {
  text-decoration: none;
}
@media (min-width: 1024px) {
.navigation-link--with-badge[data-v-86b512cc] {
    padding-right: calc(var(--magic-space, 16px) + var(--magic-space--small, 8px));
}
}
.navigation-link[data-v-86b512cc]::before {
  content: " ";
  background-color: var(--magic-primary, #e9bb41);
  position: absolute;
  top: calc(100% - var(--magic-top-navigation-active-tab-height, 2px));
  left: 50%;
  width: 0%;
  height: var(--magic-top-navigation-active-tab-height, 2px);
  transform: translate(-50%, 0);
  transition: width 0.4s;
}
@media (min-width: 1024px) {
.navigation-link[data-v-86b512cc]::before {
    top: 90%;
}
}
.navigation-link[data-v-86b512cc]:hover::before {
  width: 0;
}
@media (min-width: 1024px) {
.navigation-link[data-v-86b512cc]:hover::before {
    width: 100%;
}
}
.navigation-link.is-active[data-v-86b512cc] {
  color: #fff;
}
.navigation-link.is-active[data-v-86b512cc]::before {
  width: 100%;
}
@media (min-width: 1024px) {
.menu--logged-in[data-v-86b512cc] {
    margin: 0 var(--magic-space--small, 8px) 0 0;
}
}
.menu-icon[data-v-86b512cc] {
  grid-area: icon;
  justify-self: start;
  align-self: center;
  color: white;
  width: var(--magic-top-navigation-menu-icon-size, 30px);
  height: var(--magic-top-navigation-menu-icon-size, 30px);
  cursor: pointer;
}
.top-nav--balance[data-v-86b512cc] {
  align-items: center;
  order: 2;
}
@media (min-width: 768px) {
.top-nav--balance[data-v-86b512cc] {
    padding-left: var(--magic-space, 16px);
}
}
@media (min-width: 1024px) {
.top-nav--balance[data-v-86b512cc] {
    border-left: 1px solid var(--magic-top-navigation-border-opacity, rgba(255, 255, 255, 0.2));
}
}
.side-items[data-v-86b512cc] {
  display: flex;
  order: 2;
  align-items: center;
}
.badge[data-v-86b512cc] {
  --magic-badge-background: var(--magic-secondary, #ce32af);
}
.badge--nav-links[data-v-86b512cc] {
  position: absolute;
  top: calc(var(--magic-space--small, 8px) + var(--magic-space--xx-small, 2px));
  right: var(--magic-space--small, 8px);
}
@media (min-width: 768px) {
.badge--nav-links[data-v-86b512cc] {
    right: var(--magic-space--x-large, 64px);
}
}
@media (min-width: 1024px) {
.badge--nav-links[data-v-86b512cc] {
    right: calc(-1 * var(--magic-space--xx-small, 2px));
}
}
.badge--menu[data-v-86b512cc] {
  position: absolute;
  top: calc(var(--magic-space--small, 8px) + var(--magic-space--xx-small, 2px));
  right: calc(var(--magic-space--small, 8px) + var(--magic-space--xx-small, 2px));
}
@media (min-width: 1024px) {
.badge--menu[data-v-86b512cc] {
    right: calc(var(--magic-space--large, 32px) + var(--magic-space--xx-small, 2px));
}
}
.progress-bar[data-v-86b512cc] {
  width: var(--magic-top-navigation-progress-bar-size, calc(160px + var(--magic-space--large, 32px)));
}
.top-nav-shade[data-v-86b512cc] {
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: var(--magic-top-navigation-tab-height, 48px);
  width: var(--magic-top-navigation-shade-width, 48px);
}
.top-nav-shade--left[data-v-86b512cc] {
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), transparent);
}
.top-nav-shade--right[data-v-86b512cc] {
  right: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.8), transparent);
}
.top-nav-shade--visible[data-v-86b512cc] {
  opacity: 1;
}
@media (min-width: 1024px) {
.top-nav-shade[data-v-86b512cc] {
    display: none;
}
}
.is-sga .has-panic-bar[data-v-86b512cc] {
  transform: translate3d(0, var(--magic-sga-bar-height--m, 50px), 0);
}
.is-lotba .has-panic-bar[data-v-86b512cc] {
  transform: translate3d(0, var(--magic-lotba-bar-height--m, 70px), 0);
}
.is-agco .has-panic-bar[data-v-86b512cc] {
  transform: translate3d(0, var(--magic-agco-bar-height--m, 32px), 0);
}
.is-emta .has-panic-bar[data-v-86b512cc] {
  transform: translate3d(0, var(--magic-emta-bar-height--m, 32px), 0);
}
@media (min-width: 1024px) {
.is-sga .has-panic-bar[data-v-86b512cc] {
    transform: translate3d(0, var(--magic-sga-bar-height--d, 44px), 0);
}
.is-lotba .has-panic-bar[data-v-86b512cc] {
    transform: translate3d(0, var(--magic-lotba-bar-height--d, 50px), 0);
}
.is-agco .has-panic-bar[data-v-86b512cc] {
    transform: translate3d(0, var(--magic-agco-bar-height--d, 32px), 0);
}
.is-emta .has-panic-bar[data-v-86b512cc] {
    transform: translate3d(0, var(--magic-emta-bar-height--d, 32px), 0);
}
}