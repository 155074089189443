.bottom-navigation[data-v-54f60ef6] {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: calc(var(--magic-space--small, 8px) + var(--magic-space--x-small, 4px));
  width: 100%;
  height: var(--magic-bottom-navigation-height, 64px);
  background-color: var(--magic-tertiary, #0b124f);
  box-shadow: inset 0 1px 0 0 var(--magic-bottom-navigation-light-transparent, rgba(255, 255, 255, 0.1));
  color: var(--magic-tertiary-contrast, #fff);
  z-index: 2;
}
@supports (padding-bottom: constant(safe-area-inset-bottom)) {
.bottom-navigation[data-v-54f60ef6] {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    height: calc(var(--safe-area-inset-bottom) + var(--magic-bottom-navigation-height, 64px));
}
}
@supports (padding-bottom: env(safe-area-inset-bottom)) {
.bottom-navigation[data-v-54f60ef6] {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    height: calc(var(--safe-area-inset-bottom) + var(--magic-bottom-navigation-height, 64px));
}
}
@media (min-width: 1024px) {
.bottom-navigation[data-v-54f60ef6] {
    display: none;
}
}
.is-fullscreen .bottom-navigation[data-v-54f60ef6], .bottom-navigation.is-fullscreen[data-v-54f60ef6] {
  display: none;
}
.link[data-v-54f60ef6] {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 0 var(--magic-space, 16px);
  text-decoration: none;
  transition: color var(--magic-duration, 0.3s) ease;
  will-change: color;
}
.link[data-v-54f60ef6]:hover, .link[data-v-54f60ef6]:focus {
  color: var(--magic-link-light-transparent, rgba(255, 255, 255, 0.75));
}
.link[data-v-54f60ef6]:active, .link.is-active[data-v-54f60ef6] {
  color: var(--magic-primary, #e9bb41);
}
.link:active .icon[data-v-54f60ef6], .link.is-active .icon[data-v-54f60ef6] {
  color: var(--magic-primary, #e9bb41);
}
.icon[data-v-54f60ef6] {
  width: var(--magic-icon-size, 24px);
  height: var(--magic-icon-size, 24px);
  transition: color var(--magic-duration, 0.3s) ease;
  will-change: color;
}
.text[data-v-54f60ef6] {
  font-size: 0.69rem;
}
.badge[data-v-54f60ef6] {
  position: absolute;
  top: 0;
  right: var(--magic-space--x-small, 4px);
}